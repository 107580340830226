import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../constants/mediaqueries';
import { COLOR_BLACK } from '../constants/colors';


const Wrapper = styled.span`
  display: inline-block;
  position: absolute;

  span {
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    width: 0.8rem;
    height: 1px;
    background: ${COLOR_BLACK};

    &:first-child,
    &:nth-child(2) {
      width: .35rem;
      transform-origin: right;
    }

    &:first-child {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }

    &:nth-child(3) {
      top: 50%;
    }
  }

  @media ${breakpoints.l} {
    top: 0.25em;
  }
`;


const Arrow = ({ className }) => (
  <Wrapper className={className}>
    <span />
    <span />
    <span />
  </Wrapper>
);

Arrow.propTypes = {
  className: PropTypes.string,
};

export default Arrow;
