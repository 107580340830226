import React from 'react';


const ExternalLinkIcon = () => (
  <svg height="7" style={{ display: 'inline-block', position: 'relative', bottom: '2px' }} viewBox="0 0 14 14" width="7" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.301 1.581 0 12.882 1.118 14 12.419 2.699v8.634H14V0H2.667v1.581z" fill="currentColor" fillRule="evenodd" />
  </svg>
);

export default ExternalLinkIcon;
