import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';

import { TOGGLE_NAV } from '../state/actionTypes';
import { SPACING_SIDE_MOBILE } from '../constants/spacings';
import { COLOR_BLACK } from '../constants/colors';
import { breakpoints } from '../constants/mediaqueries';
import { fontStyles } from '../constants/styles';
import { isIntroPage } from '../helpers/location';
import { isTinyViewport, isXSmallViewport, isSmallViewport } from '../helpers/window';
import { getNavOpen, getWindowSize } from '../state/reducers';
import { windowSizeTypes } from '../types';


const Wrapper = styled.button`
  position: absolute;
  top: calc(${SPACING_SIDE_MOBILE} / 2); /* 20/16 */
  left: calc(${SPACING_SIDE_MOBILE} / 2); /* 20/16 */
  width: calc(${SPACING_SIDE_MOBILE} / 2); /* 20/16 */
  height: calc(${SPACING_SIDE_MOBILE} / 2); /* 20/16 */
  background: transparent;
  z-index: 2;
  cursor: pointer;
  border: 0 none;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
  }

  @media ${breakpoints.l} {
    display: none;
  }

  &:focus {
    outline none;
  }

  span,
  span:before,
  span:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.063rem; /* 1/16 */
    position: absolute;
    left: 0;
    background: ${COLOR_BLACK};
    transform-origin: left top;
    transition: transform .3s ease-out;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: .22s;
    transition-property: transform;
    transform-origin: center;
  }

  span {
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transition-delay: ${(props) => (props.open ? '.12s' : 0)};
    transform: ${(props) => (props.open ? 'rotate(225deg)' : '')};
    position: absolute;

    &:before {
      top: ${(props) => (props.open ? '0' : '-0.313rem')}; /* -5/16 */
      opacity: ${(props) => (props.open ? 0 : 1)};
      transition: ${(props) => (props.open ? 'top .1s ease-out, opacity .1s ease-out .12s' : 'top .1s ease-in .25s, opacity .1s ease-in')};
    }

    &:after {
      bottom: ${(props) => (props.open ? '0' : '-0.313rem')}; /* -5/16 */
      transform: ${(props) => (props.open ? 'rotate(-90deg)' : '')};
      transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
      transition: ${(props) => (props.open ? 'bottom .1s ease-out, transform .22s cubic-bezier(.215,.61,.355,1) .12s' : 'bottom .1s ease-in .25s, transform .22s cubic-bezier(.55,.055,.675,.19)')};
    }
  }
`;

const MenuButton = styled.div`
  position: absolute;
  top: calc(${SPACING_SIDE_MOBILE} / 2); /* 20/16 */
  left: calc(${SPACING_SIDE_MOBILE} / 2); /* 20/16 */
  z-index: 2;
  background: transparent;
  ${fontStyles.nav}
  transition: opacity .3s;
  opacity: ${(props) => (props.open ? 0 : 1)};
  ${(props) => (props.open && 'pointer-events: none')};
  cursor: pointer;

  @media ${breakpoints.l} {
    left: 2vw;
  }
`;

const MenuToggle = ({ intl: { formatMessage }, open, toggle, location, windowSize }) => {
  const smallDevice = isTinyViewport(windowSize) || isXSmallViewport(windowSize) || isSmallViewport(windowSize);
  return (
    <Choose>
      <When condition={isIntroPage(location) && !smallDevice}>
        <MenuButton onClick={toggle} open={open}><FormattedMessage id="global.menuButton" /></MenuButton>
      </When>
      <Otherwise>
        <Wrapper aria-label={formatMessage({ id: open ? 'global.menuButtonClose' : 'global.menuButton' })} onClick={toggle} open={open}>
          <span />
        </Wrapper>
      </Otherwise>
    </Choose>
  );
};

MenuToggle.propTypes = {
  intl: intlShape.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  windowSize: windowSizeTypes.isRequired,
};

const mapStateToProps = (state) => ({
  open: getNavOpen(state),
  windowSize: getWindowSize(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch({ type: TOGGLE_NAV }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MenuToggle));
