import React from 'react';
import styled from 'styled-components';

import {
  COLOR_BLACK, COLOR_GRAY, COLOR_OUTLINE_BLUE, COLOR_WHITE,
} from '../constants/colors';


const StyledToggleSwitch = styled.input`{
    --toggle-diameter: 12px;
    --ease-core-in-out-sine: cubic-bezier(0.37, 0, 0.63, 1);

    position: relative;
    width: calc(var(--toggle-diameter) * 2 + 4px);
    height: calc(var(--toggle-diameter) + 4px);

    transition: background-color 0.1s var(--ease-core-in-out-sine);

    border-radius: calc(var(--toggle-diameter) + 4px);
    outline: none;

    background-color: ${COLOR_GRAY};
    appearance: none;

    cursor: pointer;

    &[aria-disabled="true"] {
      cursor: not-allowed;
    }

    &:focus {
      outline: 2px solid ${COLOR_OUTLINE_BLUE};
      outline-offset: 1px;
    }

    &::before {
      content: '';

      position: absolute;
      top: 2px;
      left: 2px;
      width: var(--toggle-diameter);
      height: var(--toggle-diameter);

      transition: transform 0.1s var(--ease-core-in-out-sine);

      border-radius: 50%;

      background-color: ${COLOR_WHITE};
    }

    &:checked {
      background-color: ${COLOR_BLACK};

      &::before {
        transform: translateX(100%);
      }
    }
  }
`;

export default function ToggleSwitch(props) {
  return <StyledToggleSwitch type="checkbox" {...props} />;
}
