import React from 'react';
import PropTypes from 'prop-types';


const ChevronUpSymbol = ({ color, width }) => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 13L10 7L3 13" fill="none" stroke={color} strokeWidth={width} />
  </svg>
);

ChevronUpSymbol.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
};

ChevronUpSymbol.defaultProps = {
  color: 'black',
  width: '2px',
};

export default ChevronUpSymbol;
