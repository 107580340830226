import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import {
  defaultLangKey, locales, siteTitle, siteUrl,
} from '../data/metadata';
import { canUseDOM } from '../helpers/dom';
import { findValueForLocale, getCurrentLangKey, getSingletonForLang } from '../helpers/i18n';
import { isIntroPage } from '../helpers/location';
import { isLargeViewport, isXLargeViewport } from '../helpers/window';
import { getNavOpen, getWindowSize } from '../state/reducers';
import { windowSizeTypes } from '../types';

import Header from './Header';
import MenuToggle from './MenuToggle';
import Navigation from './Navigation';
import CookieDialog from './CookieDialog';


const Layout = ({ children, hideBookingLink, languages, location, open, windowSize }) => (
  <StaticQuery
    query={graphql`
      {
        allDatoCmsNavigation {
          edges {
            node {
              locale
              roomsPage {
                slug
                name
                rooms {
                  id
                  slug
                  name
                }
                gradient {
                  color {
                    hex
                  }
                }
              }
              restaurantPage {
                slug
                name
                navChildren {
                  ... on DatoCmsMenu {
                    id
                    slug
                    name
                  }
                  ... on DatoCmsCafeBarSubpage {
                    id
                    slug
                    name
                  }
                }
                gradient {
                  color {
                    hex
                  }
                }
              }
              eventRoomsPage {
                slug
                name
                rooms {
                  id
                  slug
                  name
                }
                gradient {
                  color {
                    hex
                  }
                }
              }
              familyPage {
                slug
                name
                gradient {
                  color {
                    hex
                  }
                }
              }
              jobs {
                name
                url
              }
              whatsOn {
                name
                url
              }
              shop {
                name
                url
              }
            }
          }
        }
        # Cookie consent dialog
        cms {
          cookieConsentDialog {
            _allDialogTextLocales {
              locale
              value
            }
            _allAcceptAllLabelLocales {
              locale
              value
            }
            _allAcceptSelectedLabelLocales {
              locale
              value
            }
            _allAnalyticsLabelLocales {
              locale
              value
            }
            _allAnalyticsTextLocales {
              locale
              value
            }
            _allDeclineLabelLocales {
              locale
              value
            }
            _allEssentialLabelLocales {
              locale
              value
            }
            _allEssentialTextLocales {
              locale
              value
            }
            _allMarketingLabelLocales {
              locale
              value
            }
            _allMarketingTextLocales {
              locale
              value
            }
            _allOptionsLabelLocales {
              locale
              value
            }
          }
        }
        # Optional fields
        cms {
          navigation {
            aboutPage {
              _allSlugLocales {
                locale
                value
              }
              _allNameLocales {
                locale
                value
              }
            }
            familyPage {
              navChildren {
                ... on DatoCms_FarmRecord {
                  id
                  __typename
                  _allSlugLocales {
                    locale
                    value
                  }
                  _allNameLocales {
                    locale
                    value
                  }
                }
                ... on DatoCms_OffersPageRecord {
                  id
                  __typename
                  _allSlugLocales {
                    locale
                    value
                  }
                  _allNameLocales {
                    locale
                    value
                  }
                }
                ... on DatoCms_ExternalNavLinkRecord {
                  id
                  __typename
                  _allUrlLocales {
                    locale
                    value
                  }
                  _allNameLocales {
                    locale
                    value
                  }
                }
              }
            }
          }
        }
      }`}
    render={(data) => {
      const url = canUseDOM ? location.pathname : '';
      const langKey = getCurrentLangKey(locales, defaultLangKey, url);

      const isNavVisible = isLargeViewport(windowSize) || isXLargeViewport(windowSize)
        ? (!isIntroPage(location) || open)
        : open;

      const navigationData = getSingletonForLang(data.allDatoCmsNavigation, langKey);
      const { eventRoomsPage, familyPage, jobs, restaurantPage, roomsPage, shop, whatsOn } = navigationData;

      /* eslint-disable no-underscore-dangle */
      let aboutPage;
      const { navigation, cookieConsentDialog } = data.cms;
      if (navigation.aboutPage) {
        aboutPage = {
          slug: findValueForLocale(navigation.aboutPage._allSlugLocales, langKey),
          name: findValueForLocale(navigation.aboutPage._allNameLocales, langKey),
        };
      }

      const familyPageNavChildren = data.cms.navigation.familyPage.navChildren;
      familyPage.navChildren = familyPageNavChildren.map((c) => ({
        id: c.id,
        slug: findValueForLocale(c._allSlugLocales, langKey),
        name: findValueForLocale(c._allNameLocales, langKey),
        url: findValueForLocale(c._allUrlLocales, langKey),
      }));

      const cookieConsentDialogData = {
        dialogText: findValueForLocale(cookieConsentDialog._allDialogTextLocales, langKey),
        acceptAllLabel: findValueForLocale(cookieConsentDialog._allAcceptAllLabelLocales, langKey),
        acceptSelectedLabel: findValueForLocale(cookieConsentDialog._allAcceptSelectedLabelLocales, langKey),
        analyticsLabel: findValueForLocale(cookieConsentDialog._allAnalyticsLabelLocales, langKey),
        analyticsText: findValueForLocale(cookieConsentDialog._allAnalyticsTextLocales, langKey),
        declineLabel: findValueForLocale(cookieConsentDialog._allDeclineLabelLocales, langKey),
        essentialLabel: findValueForLocale(cookieConsentDialog._allEssentialLabelLocales, langKey),
        essentialText: findValueForLocale(cookieConsentDialog._allEssentialTextLocales, langKey),
        marketingLabel: findValueForLocale(cookieConsentDialog._allMarketingLabelLocales, langKey),
        marketingText: findValueForLocale(cookieConsentDialog._allMarketingTextLocales, langKey),
        optionsLabel: findValueForLocale(cookieConsentDialog._allOptionsLabelLocales, langKey),
      }

      /* eslint-enable no-underscore-dangle */

      return (
        <Fragment>
          <Helmet title={siteTitle}>
            <For each="l" of={languages.filter((l) => l.langKey !== langKey)}>
              <link key={`lang_${l.langKey}`} href={`${siteUrl}${l.link}`} hrefLang={l.langKey} rel="alternate" />
            </For>
          </Helmet>
          <MenuToggle location={location} />
          <Header bookingPath={`/${roomsPage.slug}`} hideBookingLink={hideBookingLink} location={location} siteTitle={siteTitle} windowSize={windowSize} />
          <Navigation
            aboutPage={aboutPage}
            eventRoomsPage={eventRoomsPage}
            familyPage={familyPage}
            jobs={jobs}
            restaurantPage={restaurantPage}
            roomsPage={roomsPage}
            shop={shop}
            visible={isNavVisible}
            whatsOn={whatsOn}
          />
          {children}
          <CookieDialog {...cookieConsentDialogData} />
        </Fragment>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  hideBookingLink: PropTypes.bool,
  languages: PropTypes.array,
  location: PropTypes.object,
  open: PropTypes.bool,
  windowSize: windowSizeTypes.isRequired,
};

Layout.defaultProps = {
  languages: [],
};

const mapStateToProps = (state) => ({
  open: getNavOpen(state),
  windowSize: getWindowSize(state),
});

export default connect(mapStateToProps)(Layout);
