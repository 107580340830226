/* eslint-disable jsx-a11y/no-autofocus */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cookie from 'js-cookie';

import { COOKIE_CONSENT, DECLINE_OPTIONAL_COOKIES_EXPIRY, OPTIONAL_COOKIES_EXPIRY } from '../constants/cookies';
import { buttonStyles, fontStyles, linkStyles } from '../constants/styles';
import { COLOR_BLACK, COLOR_OUTLINE_BLUE, COLOR_WHITE } from '../constants/colors';
import { gaHandleConsent } from '../helpers/ga';
import { customMarkdown } from '../helpers/markdown';

import { fbqHandleConsent } from '../helpers/meta';

import CookieItem from './CookieItem';
import ChevronUpSymbol from './ChevronUpSymbol';


const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.25rem;
  align-items: center;
  width: 100%;
`;

const StyledDetailsContent = styled.div`
  display: grid;
  padding-top: 1.25rem;
  row-gap: 1.25rem;
`;

const StyledSummary = styled.summary`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 0.3125rem;
  list-style: none;

  ${fontStyles.bodySmall}

  cursor: pointer;

  &:focus {
    outline: 2px solid ${COLOR_OUTLINE_BLUE};
  }

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }

  svg {
    width: 1em;
    height: 1em;
    transform: rotateZ(180deg);
  }
`;

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1.25rem;
`;

const StyledDetails = styled.details`
  &[open] {
    flex-grow: 1

    + ${StyledButtonGroup} {
      display: none;
    }

    > ${StyledSummary} svg {
      transform: rotate(90deg);
    }
  }

  &:not(&[open]) > ${StyledDetailsContent} {
    display: none;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles}
  background-color: ${(props) => props.outlined ? COLOR_WHITE : COLOR_BLACK};
  color: ${(props) => props.outlined ? COLOR_BLACK : COLOR_WHITE};
  cursor: pointer;
`;

const StyledDialogText = styled.div`
  a {
    ${linkStyles}
  }
`;

class CookieConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketing: false,
      analytics: false,
    };
  }

  setConsentCookie = (value, days) => {
    const options = {
      expires: days,
      path: '/',
    };

    if (window.location.hostname !== 'localhost') {
      // Only set the domain if we’re not on localhost
      options.domain = window.location.hostname;
    }

    Cookie.set(COOKIE_CONSENT, JSON.stringify(value), options);

    gaHandleConsent();
    fbqHandleConsent();

    this.props.onCookieSet();
  };

  acceptAllCookies = () => {
    const specialCookieConsent = {
      ...Object.fromEntries(
        Object.keys(this.state).map((key) => [key, true]),
      ),
    };

    this.setConsentCookie(specialCookieConsent, OPTIONAL_COOKIES_EXPIRY);
  };

  acceptSelectedCookies = () => {
    this.setConsentCookie(this.state, OPTIONAL_COOKIES_EXPIRY);
  };

  declineCookies = () => {
    const specialCookieConsent = {
      ...Object.fromEntries(
        Object.keys(this.state).map((key) => [key, false]),
      ),
    };

    this.setConsentCookie(specialCookieConsent, DECLINE_OPTIONAL_COOKIES_EXPIRY);
  };

  handleConsentInputChange = (
    event,
    key,
  ) => {
    this.setState({
      [key]: event.target.checked,
    });
  };

  render() {
    return (
      <React.Fragment>
        <StyledDialogText>{customMarkdown(this.props.dialogText)}</StyledDialogText>
        <StyledDetailsWrapper>
          <StyledDetails>
            <StyledSummary>
              <span>{this.props.optionsLabel}</span>
              <ChevronUpSymbol />
            </StyledSummary>
            <StyledDetailsContent>
              <CookieItem
                checked
                description={this.props.essentialText}
                disabled
                label={this.props.essentialLabel}
              />
              <CookieItem
                checked={this.state.analytics}
                description={this.props.analyticsText}
                label={this.props.analyticsLabel}
                onChange={(event) => this.handleConsentInputChange(event, 'analytics')}
              />
              <CookieItem
                checked={this.state.marketing}
                description={this.props.marketingText}
                label={this.props.marketingLabel}
                onChange={(event) => this.handleConsentInputChange(event, 'marketing')}
              />
              <StyledButtonGroup>
                <StyledButton
                  onClick={this.declineCookies}
                  outlined
                  type="button"
                >
                  {this.props.declineLabel}
                </StyledButton>
                <StyledButton
                  autoFocus
                  onClick={this.acceptSelectedCookies}
                  type="button"
                >
                  {this.props.acceptSelectedLabel}
                </StyledButton>
              </StyledButtonGroup>
            </StyledDetailsContent>
          </StyledDetails>
          <StyledButtonGroup>
            <StyledButton
              onClick={this.declineCookies}
              outlined
              type="button"
            >
              {this.props.declineLabel}
            </StyledButton>
            <StyledButton
              autoFocus
              onClick={this.acceptAllCookies}
              type="button"
            >
              {this.props.acceptAllLabel}
            </StyledButton>
          </StyledButtonGroup>
        </StyledDetailsWrapper>
      </React.Fragment>
    );
  }
}

CookieConsent.propTypes = {
  dialogText: PropTypes.string.isRequired,
  acceptAllLabel: PropTypes.string.isRequired,
  acceptSelectedLabel: PropTypes.string.isRequired,
  analyticsLabel: PropTypes.string.isRequired,
  analyticsText: PropTypes.string.isRequired,
  declineLabel: PropTypes.string.isRequired,
  essentialLabel: PropTypes.string.isRequired,
  essentialText: PropTypes.string.isRequired,
  marketingLabel: PropTypes.string.isRequired,
  marketingText: PropTypes.string.isRequired,
  optionsLabel: PropTypes.string.isRequired,
  onCookieSet: PropTypes.func.isRequired,
};

export default CookieConsent;
