import React, { Component } from 'react';
import styled from 'styled-components';
import Cookie from 'js-cookie';

import { COOKIE_BANNER_ID, COOKIE_CONSENT } from '../constants/cookies';
import { closeDialog, showDialog } from '../helpers/dialog';

import { fontStyles } from '../constants/styles';

import { COLOR_BLACK } from '../constants/colors';

import CookieConsent from './CookieConsent';


const StyledDialog = styled.dialog`
  {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 32em;
    height: auto;
    margin: 0;
    padding: 1.25rem;

    border: 1px solid ${COLOR_BLACK};
    border-bottom: none;

    row-gap: 1.25rem;

    color: ${COLOR_BLACK};
    ${fontStyles.bodySmall}

    &[open] {
      display: grid;
    }
  }
`;

class CookieDialog extends Component {
  componentDidMount() {
    this.updateCookieDialog();
  }

  updateCookieDialog() {
    const cookieConsent = Cookie.get(COOKIE_CONSENT);
    const dialog = document.getElementById(COOKIE_BANNER_ID);

    if (dialog) {
      if (cookieConsent) {
        closeDialog(dialog);
      } else {
        showDialog(dialog);
      }
    }
  }

  render() {
    return (
      <StyledDialog
        aria-label="cookie consent dialog"
        id={COOKIE_BANNER_ID}
      >
        <CookieConsent {...this.props} onCookieSet={this.updateCookieDialog} />
      </StyledDialog>
    );
  }
}

export default CookieDialog;
