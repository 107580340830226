import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { linkStyles } from '../constants/styles';

import { customMarkdown } from '../helpers/markdown';

import ToggleSwitch from './ToggleSwitch';
import ChevronUpSymbol from './ChevronUpSymbol';


const StyledLabel = styled.span`
  display: flex;
  column-gap: 0.3125rem;
  align-items: center;
`;

const StyledCookieItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  &[open] {
    flex-grow: 1;

    ${StyledLabel} svg {
      transform: rotate(90deg);
    }
  }

  ${StyledLabel} svg {
    width: 1em;
    height: 1em;
    transform: rotate(180deg);
  }
`;

const StyledSummary = styled.summary`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  list-style: none;

  cursor: pointer;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }

  &:focus {
    outline: 2px solid rgb(55, 55, 230);
  }
`;

const StyledDescription = styled.div`
  display: grid;
  padding-top: 0.625rem;
  row-gap: 1.25rem;

  a {
    ${linkStyles}
  }
`;

const StyledDetails = styled.details`
  grid-area: 1 / 1 / 2 / 3;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  grid-area: 1 / 2 / 2 / 3;
`;

const CookieItem = ({
  label,
  checked,
  disabled,
  description,
  onChange = () => {},
}) => (
  <StyledCookieItem>
    <StyledDetails>
      <StyledSummary>
        <StyledLabel>
          <span>{label}</span>
          <ChevronUpSymbol />
        </StyledLabel>
      </StyledSummary>
      <StyledDescription>
        {customMarkdown(description)}
      </StyledDescription>
    </StyledDetails>
    <StyledToggleSwitch
      aria-disabled={disabled}
      aria-label={label}
      checked={checked}
      onChange={onChange}
    />
  </StyledCookieItem>
);

CookieItem.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CookieItem;
