import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import styled from 'styled-components';

import { breakpoints } from '../constants/mediaqueries';
import { SPACING_SIDE_MOBILE, SPACING_NAV_SIDE_DESKTOP } from '../constants/spacings';
import { fontStyles } from '../constants/styles';

import ExternalLink from './ExternalLink';
import ExternalLinkIcon from './ExternalLinkIcon';
import NavigationSection from './NavigationSection';


const Wrapper = styled.nav`
  position: fixed;
  top: 6.25rem; /* 100/16 */
  left: calc(${SPACING_SIDE_MOBILE} / 2);
  z-index: 1;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ease-out;
  ${fontStyles.nav}

  @media ${breakpoints.l} {
    display: block;
    top: 1.25rem;
    left: ${SPACING_NAV_SIDE_DESKTOP};
    pointer-events: auto;
  }
`;

const OutboundLink = styled.div`
  > * {
    display: block;
    line-height: 1.75;

    @media ${breakpoints.l} {
      line-height: 1.5;
    }
  }
`;

const Navigation = ({ aboutPage, eventRoomsPage, familyPage, visible, restaurantPage, roomsPage, shop, whatsOn, jobs }) => (
  <Location>
    {({ location }) => (
      <Wrapper visible={visible}>
        <If condition={aboutPage}>
          <NavigationSection
            location={location}
            name={aboutPage.name}
            parentindex={1}
            slug={aboutPage.slug}
          />
        </If>
        <NavigationSection
          links={roomsPage.rooms}
          location={location}
          name={roomsPage.name}
          parentindex={aboutPage ? 2 : 1}
          slug={roomsPage.slug}
        />
        <NavigationSection
          links={restaurantPage.navChildren}
          location={location}
          name={restaurantPage.name}
          parentindex={aboutPage ? 3 : 2}
          slug={restaurantPage.slug}
        />
        <NavigationSection
          links={eventRoomsPage.rooms}
          location={location}
          name={eventRoomsPage.name}
          parentindex={aboutPage ? 4 : 3}
          slug={eventRoomsPage.slug}
        />
        <NavigationSection
          links={familyPage.navChildren}
          location={location}
          name={familyPage.name}
          parentindex={aboutPage ? 5 : 4}
          slug={familyPage.slug}
        />
        <OutboundLink>
          <span><ExternalLink hasUnderline={false} href={jobs.url}>{jobs.name} <ExternalLinkIcon /></ExternalLink></span>
        </OutboundLink>
        <OutboundLink>
          <a href={whatsOn.url}>{whatsOn.name}</a>
        </OutboundLink>
        <OutboundLink>
          <span><ExternalLink hasUnderline={false} href={shop.url}>{shop.name} <ExternalLinkIcon /></ExternalLink></span>
        </OutboundLink>
      </Wrapper>
    )}
  </Location>
);


Navigation.propTypes = {
  aboutPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  eventRoomsPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rooms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  familyPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    navChildren: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ])).isRequired,
  }).isRequired,
  restaurantPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    navChildren: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  roomsPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rooms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  shop: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  whatsOn: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  jobs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  visible: PropTypes.bool,
};

export const NakedNavigation = Navigation;

export default Navigation;
