import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { COLOR_BLACK } from '../constants/colors';
import { breakpoints } from '../constants/mediaqueries';
import { fonts } from '../constants/typography';
import { getNavOpen } from '../state/reducers';

import Arrow from './Arrow';
import LocalisedLink from './LocalisedLink';
import ExternalLink from './ExternalLink';
import ExternalLinkIcon from './ExternalLinkIcon';


const Section = styled.div`
  > span,
  li > span {
    display: block;
    color: ${COLOR_BLACK};
    line-height: 1.75;

    @media ${breakpoints.l} {
      line-height: 1.5;
    }
  }

  > span {
    ${(props) => (props.active && `font-family: ${fonts.fontFamilyItalic}`)};
    ${(props) => (props.active && 'font-style: italic')};
  }

  > span span {
    display: block;
  }

  a {
    text-decoration: none;
  }

  .arrow {
    top: 0.25em;
    left: 0;

    display: inline-block;
    width: 0.8rem;
    height: 1rem;

    opacity: 0;
    transform: translateX(-0.7rem);
  }

  .arrow.active {
    opacity: 1;
    transform: translateX(0);
    transition: transform .3s ease-out;
  }
`;

const ListItem = styled.li`
  position: relative;
  max-height: 3rem;
  overflow: hidden;

  ${(props) => (props.active && `font-family: ${fonts.fontFamilyItalic}`)};
  ${(props) => (props.active && 'font-style: italic')};

  > span {
    padding-left: 1.1rem;
  }
`;

const NavigationSection = ({ links, location, name, open, parentindex, slug }) => {
  const pathSections = location.pathname.split('/');
  const path = pathSections[2];
  const subPath = pathSections[pathSections.length - 1];
  const sectionIsActive = path === slug;

  return (
    <Section active={sectionIsActive} open={open}>
      <span>
        <LocalisedLink hasUnderline={false} to={`/${slug}`}>
          <span>{name}</span>
        </LocalisedLink>
      </span>
      <If condition={sectionIsActive && typeof links !== 'undefined'}>
        <ul>
          <For each="link" index="i" of={links}>
            <Choose>
              <When condition={link.url}>
                <ListItem
                  key={link.url}
                  index={i}
                  numberEls={links.length}
                  parentindex={parentindex}
                >
                  <span>
                    <ExternalLink hasUnderline={false} href={link.url}>{link.name} <ExternalLinkIcon /></ExternalLink>
                  </span>
                </ListItem>
              </When>
              <Otherwise>
                <ListItem
                  key={link.slug}
                  active={link.slug === subPath}
                  index={i}
                  numberEls={links.length}
                  parentindex={parentindex}
                >
                  <span>
                    <Arrow className={link.slug === subPath ? 'arrow active' : 'arrow'} />
                    <LocalisedLink hasUnderline={false} to={`/${slug}/${link.slug}`}>{link.name}</LocalisedLink>
                  </span>
                </ListItem>
              </Otherwise>
            </Choose>
          </For>
        </ul>
      </If>
    </Section>
  );
};

NavigationSection.propTypes = {
  parentindex: PropTypes.number,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ])),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  open: getNavOpen(state),
});

export const NakedNavigation = NavigationSection;

export default connect(mapStateToProps)(NavigationSection);
